<template>
    <div class="home-com-container">
        <div class="home-com-img-bg" :class="[item.type == 2 ? 'order-1' : 'order-2']">
            <img class="home-com-img" :src="img(item.imgUrl)" alt="图标">
        </div>
        <div class="home-com-content-bg">
            <div class="home-com-content-title">{{ item.title }}</div>
            <div class="home-com-content-desc">{{ item.desc }}</div>
            <el-button class="home-com-content-btn" style="display: none;" @click="handleClick">{{ item.actionTitle }}</el-button>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    item: {
        type: Object,
        default: () => {}
    }
})
const img = computed(() => (url) => require(`@/assets/images/home/${url}`));

const handleClick = () => {

}

</script>

<style lang="scss" scoped>
.home-com-container{
    width: 80%;
    margin: 40px 0 180px;
    display: flex;
    flex-direction: row;
    .home-com-img-bg{
        max-width: 745px;
        width: 48%;
        .home-com-img{
            width: 100%;
        }
    }
    .home-com-content-bg{
        width: 50%;
        color: #fff;
        .home-com-content-title{
            margin-top: 20px;
            font-size: 45px;
            font-weight: 600;
            line-height: 70px;
            text-align: left;
        }
        .home-com-content-desc{
            margin-top: 134px;
            color: #9397bc;
            font-size: 22px;
            font-weight: 300;
            line-height: 50px;
            text-align: left;
        }
        .home-com-content-btn{
            margin-top: 100px;
            position: relative;
            border-radius: 8px;
            width: 176px;
            height: 62px;
            background: linear-gradient(90deg, #67e7ff 0%, #9c4eff 100%);
            color: #010612;
            font-family: MiSans;
            font-size: 22px;
            font-weight: 600;
            line-height: 36px;
            text-align: center;
            cursor: pointer;
            border-width: 0;
            &:active{
                opacity: 0.6;
            }
        }
    }
    .order-1{
        order: -1;
        margin-right: 50px;
    }
    .order-2{
        order: 1;
        margin-left: 50px;
    }
}
</style>
