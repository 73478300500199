import { render, staticRenderFns } from "./HomeCom.vue?vue&type=template&id=4e3f17b6&scoped=true"
import script from "./HomeCom.vue?vue&type=script&setup=true&lang=js"
export * from "./HomeCom.vue?vue&type=script&setup=true&lang=js"
import style0 from "./HomeCom.vue?vue&type=style&index=0&id=4e3f17b6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e3f17b6",
  null
  
)

export default component.exports