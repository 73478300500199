<template>
    <div class="home-sec-container">
        <div class="home-sec-title">{{item.name}}</div>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" v-for="it in item.list" :key="it.id">
                <div class="home-sec-item" :style="showStyle(it.imgUrl)">
                     <div class="home-sec-item-title">{{ it.name }}</div>
                     <div class="home-sec-item-desc">{{ it.desc }}</div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    item: {
        type: Object,
        default: () => {}
    }
})
const showStyle = computed(() => (imgUrl) => {
    return imgUrl ? {
        'background': `url(${require(`@/assets/images/home/${imgUrl}`)}) no-repeat`,
        'background-size': 'cover',
        'background-position': 'top right'
    } : {}
});

</script>

<style lang="scss" scoped>
.home-sec-container{
    width: 80%;
    color: white;
    margin-bottom: 160px;
    .home-sec-title{
        text-align: center;
        font-size: 45px;
        font-weight: 600;
        margin-bottom: 100px;
    }
    .home-sec-item{
        cursor: pointer;
        height: 325px;
        width: 100%;
        background: #1f2340;
        box-sizing: border-box;
        border-radius: 6px;
        margin-bottom: 20px;
        padding: 57px 52px 48px 38px;
        position: relative; // 添加相对定位
        .home-sec-item-title{
            font-size: 30px;
            font-weight: 600;
            line-height: 40px;
        }
        .home-sec-item-desc{
            margin-top: 105px;
            color: #757895;
            font-size: 18px;
            font-weight: 400;
            text-align: left;
        }
        &:active{
            opacity: 0.6;
        }
    }
}
</style>
