<template>
    <div class="home-top-bg">
        <div class="home-top-title">万界数据 MaaS 平台</div>
        <div class="home-top-subtitle">汇聚前沿算法模型</div>
        <div class="home-top-desc">聚合 AI 能力，快速、高效地定制专属场景化模型</div>
        <el-button plain class="home-top-detail-action" style="display: none;" @click="handleDetail">了解详情</el-button>
        <img class="scoll-icon" src="@/assets/images/home/59543dea.gif" alt="">
    </div>
</template>

<script setup>

const handleDetail = () => {
    
}
</script>

<style lang="scss" scoped>
.home-top-bg{
    // background-image: url('~@/assets/images/home/background1-0e50f792.svg');
    // background-repeat: no-repeat;
    // background-size: cover;
    width: 80%;
    height: 920px;
    color: #FFF;
    font-weight: 600;
    font-family: MiSans;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .home-top-title{
        margin-top: -200px;
        font-size: 36px;
    }
    .home-top-subtitle{
        margin-top: 10px;
        font-size: 100px;
    }
    .home-top-desc{
        margin-top: 10px;
        font-weight: 400;
        font-size: 31px;
        letter-spacing: 1px;
    }
    .home-top-detail-action{
        color: #FFF;
        background: transparent;
        margin-top: 50px;
        width: 140px;
        height: 50px;
        border-radius: 8px;
        font-size: 24px;
        border: 1px solid #FFF;
        &:active{
            border: 1px solid #ddd;
            color: #ddd;
        }
    }
    .scoll-icon{
        width: 34px;
        height: 34px;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: 98px;
    }
}


</style>

