<template>
	<div class="page-container">
		<HomeTop />
		<HomeCom v-for="it in comList" :key="it.id" :item="it" />
		<HomeShow :item="lang" />
		<HomeShow :item="comp" />
		<HomeSec :item="sec" />
		<HomePlat />
		<div class="page-footer">开始使用，即刻创建您的专属 AI 模型</div>
	</div>
</template>

<script setup>
import HomeTop from '@/views/home/HomeTop.vue'
import HomeCom from '@/views/home/HomeCom.vue'
import HomeShow from '@/views/home/HomeShow.vue'
import HomeSec from '@/views/home/HomeSec.vue'
import HomePlat from '@/views/home/HomePlat.vue'

const comList = [
	{
		id: 1,
		title: 'FeedMaaS',
		desc: '以饲料原料营养价值和配方的数据库、数据查询和自然语言处理模型为主要组成部分，使用AI技术改造饲料配方制作过程中营养素含量的控制环节',
		imgUrl: 'bc3f20d6_1702022535306.gif',
		type: 1,	// 2: 左图右文，1: 右图左文
		actionTitle: '立即体验',
	}, {
		id: 2,
		title: '去除图片背景',
		desc: '针对电商行业“商品”的视觉分割，对图片中的商品主体轮廓范围进行识别后，精细分割出照片中的主体',
		imgUrl: '1702019077185.gif',
		type: 2,
		actionTitle: '立即体验',
	}, {
		id: 3,
		title: '文生图',
		desc: '输入脑海中需求场景，快速生成吸引人的电商氛围图，帮助提高营销效率以及购买转换率',
		imgUrl: '1702019060411.gif',
		type: 1,
		actionTitle: '立即体验',
	}, {
		id: 4,
		title: '家畜数量识别',
		desc: '准确地识别和追踪实体数量，帮助养殖人员实时监测家畜的活动及健康状况，本期支持猪体识别',
		imgUrl: '1702019068779.gif',
		type: 2,
		actionTitle: '立即体验',
	}
]
const lang = {
	name: '自然语言处理',
	list: [
		{
			id: 1,
			name: '多轮对话模型',
			desc: '基于LLaMa的130亿参数的大规模预训练模型，具备翻译，编程，文本分类，信息抽取，摘要，文案生成，常识问答和数学计算等能力'
		}, {
			id: 2,
			name: '通用情感分析',
			desc: '分析句子的正向或负向的情感极性。在用户评价观点抽取，意图识别中往往起到重要作用'
		}, {
			id: 3,
			name: '文本摘要',
			desc: '自然语言理解文本内容并简要一句话概括'
		}, {
			id: 4,
			name: '实体识别',
			desc: '识别文本中具有特定意义的实体，主要包括人名、地名、公司名、消费品'
		}, {
			id: 5,
			name: '中英翻译',
			desc: '基于连续语义增强的神经机器翻译，支持中文精准翻译成英文'
		}, {
			id: 6,
			name: '英中翻译',
			desc: '基于连续语义增强的神经机器翻译，支持英文精准翻译成中文'
		}, {
			id: 7,
			name: '电商情感分析',
			desc: '电商情感分析'
		}, {
			id: 8,
			name: '新闻实体识别',
			desc: '识别新闻事件的基本要素，如时间、人物、机构、地点、专有名词等'
		}, {
			id: 9,
			name: '新闻写作助手',
			desc: '专业新闻领域写作模型，支持AI写作、文本扩写、文本润色，训练集使用权威新闻语料 '
		}
	]
}
const comp = {
	name: '计算机视觉',
	list: [
		{
			id: 1000,
			name: '图像分割',
			desc: '通常用于定位图像中的物体和边界（线，曲线等），在此基础上精准分割出物体的像素'
		}, {
			id: 1001,
			name: '通用目标检测',
			desc: '算法从一幅场景中找出目标并确定目标位置。这使得同一图像内的多个目标都能被识别和定位'
		}, {
			id: 1002,
			name: 'OCR',
			desc: '对文本资料的图像文件进行分析、识别处理，精确提取图片中包含的文字信息'
		}, {
			id: 1003,
			name: '图像识别',
			desc: '对图像中特定对象的类别进行分类或预测，准确识别图像中的特征'
		}, 
	]
}
const sec = {
	name: '安全与信任',
	list: [
		{
			id: 3000,
			name: '资源透明',
			imgUrl: '12a28659.png',
			desc: '万界 HPCaaS 平台为模型应用提供模型使用及微调的充沛 GPU 算力，虚拟机的资源使用透明可见'
		}, {
			id: 3001,
			name: '私有化部署',
			imgUrl: '9fd8e49a.png',
			desc: '针对保密要求严格的场景，支持应用的私有化部署'
		}, {
			id: 3002,
			name: '数据安全',
			imgUrl: '7c274a9e.png',
			desc: '保护模型使用产生数据的安全隐私，并为用户提供具备法律效应的隐私承诺'
		}
	]
}

</script>

<style lang="scss" scoped>
.page-container{
	margin-top: 95px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.page-footer{
		background: linear-gradient(90.02deg, #37f3ff -6.63%, #9959ff 104.97%);
		-webkit-background-clip: text;
		background-clip: text;
		color: transparent;
		margin-top: 230px;
		font-size: 54px;
		font-weight: 400;
		width: calc(100% - 60px);
		line-height: 80px;
		text-align: center;
	}
}
</style>
